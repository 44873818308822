/* You can add global styles to this file, and also import other style files */
/* @import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css"; */
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
    font-family: 'AnekLatin';
    src: url('assets/fonts/Anek/AnekLatin-Regular.ttf') format('truetype');
}

body {
    font-family: 'AnekLatin', sans-serif;
}